import React, { useState, useContext } from "react";
import { Button, Form, Page } from "tabler-react";
import SiteWrapper from "../components/PageWrapper";
import { withRouter } from "react-router-dom";
import client from "../api/ApiInstance";
import { AppContext } from "../AppContext";

function Login({ history }) {
  const context = useContext(AppContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  if (client.authenticated) {
    history.push("/fulfillments");
    return null;
  }

  function handleSubmit(e) {
    e.preventDefault();
    login().then((page: string) => {
      if (page.length > 0) {
        history.push(page);
      }
    });
  }

  async function login(): Promise<string> {
    if (!username.length) {
      setError("Morate uneti email adresu");
      return "";
    }
    if (!password.length) {
      setError("Morate uneti lozinku");
      return "";
    }
    setError("");
    if (username === "admin@solagro.app" || username === 'cocacola@solagro.app') {
      try {
        await client.login(username, password);
      } catch (e) {
        setError("E-mail ili lozinka nisu ispravni");
      }
      if (client.authenticated) {
        return "/fulfillments";
      }
    }
    setError("Neispravni podaci");
    return "";
  }

  return (
    <SiteWrapper>
      <Page.Content>
        <Form onSubmit={handleSubmit}>
          <div style={{ maxWidth: 400, margin: "100px 0 200px" }}>
            {!error ? null : <p style={{ color: "#e33" }}>{error}</p>}
            <Form.Group label="Email adresa" isRequired>
              <Form.Input
                name="email"
                type="email"
                onChange={e => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group label="Lozinka" isRequired>
              <Form.Input
                name="password"
                type="password"
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Button color="primary" type="submit">
                Prijavi se
              </Button>
            </Form.Group>
          </div>
        </Form>
      </Page.Content>
    </SiteWrapper>
  );
}

export default withRouter(Login);
