import React from "react";
import { NavLink, withRouter } from "react-router-dom";

import { Site, RouterContextProvider, Button } from "tabler-react";

import limenke from "../assets/limenke-status.png";
import boce from "../assets/boce-status.png"
import HeaderLogoImage from "../assets/logo.png";

const navBarItems = [
  {
    value: <React.Fragment><img src={boce} height="70px" style={{marginBottom: 0}}/><h1 style={{color: "white", marginBottom: 0, fontWeight: 300}}>Budva Zero Waste City</h1></React.Fragment>,
    to: "/",
    LinkComponent: withRouter(NavLink),
    useExact: true
  },
  {
    value: "Odjavi se",
    to: "/logout",
    LinkComponent: withRouter(NavLink),
    useExact: true
  },
];

export function SiteWrapper(props) {
  return (
    <Site.Wrapper
    style={{justifyContent: 'center'}}
      headerProps={{
        href: "/",
        alt: "Yes, we CAN",
        imageURL: HeaderLogoImage,
        navItems: !props.logout ? (
          undefined
        ) : (
          <Button RootComponent="a" href="/">
            Odjavite se
          </Button>
        )
      }}
      navProps={{ itemsObjects: navBarItems }}
      routerContextComponentType={withRouter(RouterContextProvider)}
      footerProps={{
        copyright: (
          <React.Fragment style={{justifyContent: 'center', backgroundColor: "#222C46"}}>
            © 2019{" "}
            <a href="http://solagroteam.com">Solagro Smart Recycling d.o.o.</a>{" "}
            Sva prava zadržana.
          </React.Fragment>
        )
      }}
    >
      {props.children}
    </Site.Wrapper>
  );
}

export default SiteWrapper;
