import React from "react";

export interface RecycledItems {
  can: number;
}

export interface Stats {
  devices_can_crusher: number;
  products_available: number;
  products_purchased: number;
  recycled_items: number;
  users_registered: number;
}

export interface User {
  id: string;
  username: string;
  email: string;
  gender: "male" | "female" | "other" | "unknown";
  display_name: string;
  points: {
    [key: string]: number;
  };
  recycled_items: {
    [key: string]: RecycledItems;
  };
  total_points: number;
  total_recycled_items: RecycledItems;
}

export interface Location {
  id: string;
  name: string;
  coords: {
    lat: number;
    long: number;
  };
}

export interface Partner {
  id: string;
  name: string;
  color: string;
}

export interface Product {
  id: string;
  name: string;
  sponsor: string;
  price: number;
  available_units: number;
  image_url: string;
  partner: Partial<Partner>;
  reserved_units: number;
}

export interface Purchase {
  id: string;
  status: "unfulfilled" | "fulfilled";
  fulfilled_units: number;
  purchased_units: number;
  product: Product;
}

export interface Device {
  id: string;
  status:
    | "unknown"
    | "active"
    | "deleted"
    | "archived"
    | "offline"
    | "online"
    | "maintenance";
  name: string;
  location: {
    id: string;
    name: string;
  };
  total_recycled_items: number;
  capacity: number;
  requires_maintenance: number;
  low_capacity: number;
}

export interface Fulfillment {
  id: string;
      status: 
        | "unfulfilled"
        | "fulfilled"
        | "original" 
        | "empty" 
        | "canceled" 
        | "expired";
      fulfilled_units: number,
      reserved_units: number,
      purchase: {
        id: string,
        product: {
          id: string,
          name: string,
          sponsor: string,
          image_url: string
        },
        user: {
          id: string,
          username: string,
          email: string,
          display_name: string
        }
      }
}

export interface AppContext {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  gifts: Product[];
  setGifts: React.Dispatch<React.SetStateAction<Product[]>>;
  partners: Partner[];
  setPartners: React.Dispatch<React.SetStateAction<Partner[]>>;
  purchased: Purchase[];
  setPurchased: React.Dispatch<React.SetStateAction<Purchase[]>>;
  locations: Location[];
  setLocations: React.Dispatch<React.SetStateAction<Location[]>>;
  stats: Stats | null;
  setStats: React.Dispatch<React.SetStateAction<Stats>>;
  devices: Device[];
  setDevices: React.Dispatch<React.SetStateAction<Device[]>>;
  fulfillments: Fulfillment[];
  setFulfillments: React.Dispatch<React.SetStateAction<Fulfillment[]>>;
}

export const AppContext = React.createContext<AppContext>({
  user: null,
  setUser: () => {},
  gifts: [],
  setGifts: () => {},
  partners: [],
  setPartners: () => {},
  purchased: [],
  setPurchased: () => {},
  locations: [],
  setLocations: () => {},
  stats: null,
  setStats: () => {},
  devices: [],
  setDevices: () => {},
  fulfillments: [],
  setFulfillments: () => {},
});
