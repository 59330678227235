/// <reference path="index.d.ts" />
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Error404Page } from "tabler-react";
import "./App.css";
import {
  AppContext,
  Device,
  Location,
  Partner,
  Product,
  Purchase,
  Stats,
  User
} from "./AppContext";
import { Admin } from "./pages/Admin";
import Login from "./pages/Login";
import Logout from "./pages/Logout";

export default function App() {
  const [stats, setStats] = useState<Stats>({});
  const [user, setUser] = useState<User>({});
  const [locations, setLocations] = useState<Location[]>([]);
  const [gifts, setGifts] = useState<Product[]>([]);
  const [purchased, setPurchased] = useState<Purchase[]>([]);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [fulfillments, setFulfillments] = useState<Fulfillment[]>([]);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        locations,
        setLocations,
        partners,
        setPartners,
        purchased,
        setPurchased,
        gifts,
        setGifts,
        stats,
        setStats,
        devices,
        setDevices,
        fulfillments,
        setFulfillments
      }}
    >
      <React.StrictMode>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/fulfillments" component={Admin} />
            <Route component={Error404Page} />
          </Switch>
        </Router>
      </React.StrictMode>
    </AppContext.Provider>
  );
}
