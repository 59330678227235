import { withRouter } from "react-router-dom";
import client from "../api/ApiInstance";

function Logout({ history }) {
  if (client.authenticated) {
    client.logout();
  }
  history.push("/");
  return null;
}

export default withRouter(Logout);
