import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Form,
  Grid,
  Page,
  Table
} from "tabler-react";
import client from "../api/ApiInstance";
import { AppContext, Fulfillment } from "../AppContext";
import SiteWrapper from "../components/PageWrapper";

function formatShortDate(date: Date) {
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "maj",
    "jun",
    "jul",
    "avg",
    "sep",
    "okt",
    "nov",
    "dec"
  ];
  return `${date.getDate()}. ${months[date.getMonth()]}`;
}

function formatDate(date: Date) {
  const months = [
    "januar",
    "februar",
    "mart",
    "april",
    "maj",
    "jun",
    "jul",
    "avgust",
    "septembar",
    "oktobar",
    "novembar",
    "decembar"
  ];
  return `${date.getDate()}. ${months[date.getMonth()]} ${date.getFullYear()}.`;
}

function range(from, to) {
  return Array.from({ length: to - from }, (_, i) => i + from);
}

interface PaginationProps {
  page: number;
  numPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination = React.memo(
  ({ page, numPages, setPage }: PaginationProps) => {
    if (numPages < 2) {
      return null;
    }
    return (
      <Button.List>
        {page < 3 ? null : (
          <Button icon="chevrons-left" onClick={e => setPage(0)} />
        )}
        {page === 0 ? null : (
          <Button icon="chevron-left" onClick={e => setPage(page - 1)} />
        )}
        {range(Math.max(0, page - 2), Math.min(numPages, page + 3)).map(p => {
          return (
            <Button
              key={p}
              onClick={e => setPage(p)}
              color={p === page ? "gray-dark" : undefined}
            >
              {p + 1}
            </Button>
          );
        })}
        {page >= numPages - 1 ? null : (
          <Button icon="chevron-right" onClick={e => setPage(page + 1)} />
        )}
        {page >= numPages - 3 ? null : (
          <Button icon="chevrons-right" onClick={e => setPage(numPages - 1)} />
        )}
      </Button.List>
    );
  }
);

interface FulfillmentsTableProps {
  fulfill: (id: string) => Promise<void>;
  fulfillments: Fulfillment[];
  onFilterChange: (params: { showFulfilled: boolean }) => void;
  otherListCount: number;
  itemsPerPage?: number;
}

const FulfillmentsTable = React.memo(
  ({
    fulfill,
    fulfillments,
    onFilterChange,
    otherListCount,
    itemsPerPage = 15
  }: FulfillmentsTableProps) => {
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(0);
    const [visibleFulfillments, setVisibleFulfillments] = useState(
      fulfillments
    );
    const [showFulfilled, setShowFulfilled] = useState(false);

    useEffect(() => {
      const q = query.normalize().trim();
      if (q.length === 0) {
        setVisibleFulfillments(fulfillments);
        return;
      }
      const search = new RegExp(
        q
          .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
          .replace(/%/g, ".*?"),
        "i"
      );
      setVisibleFulfillments(
        fulfillments.filter(item => {
          return (
            search.test(item.purchase.user.display_name) ||
            search.test(item.purchase.user.email) ||
            search.test(item.purchase.user.username) ||
            search.test(item.purchase.product.name) ||
            search.test(item.purchase.product.sponsor)
          );
        })
      );
    }, [fulfillments, query]);

    useEffect(() => {
      onFilterChange({ showFulfilled });
    }, [showFulfilled]);

    function handleToggle(e) {
      e.preventDefault();
      setShowFulfilled(!showFulfilled);
    }

    const numPages = Math.ceil(visibleFulfillments.length / itemsPerPage);

    return (
      <Card>
        <Card.Header>
          <Card.Title>
            {showFulfilled ? "Preuzete nagrade" : "Nepreuzete nagrade"}
            <span style={{ marginLeft: 30 }}>
              <Button onClick={handleToggle}>
                {showFulfilled ? "Prikaži nepreuzete" : "Prikaži preuzete"}
                {` (${otherListCount})`}
              </Button>
            </span>
          </Card.Title>
          <Button.List align="right" style={{ marginLeft: "auto" }}>
            <Form.InputGroup>
              <Form.Input
                onChange={e => {
                  setQuery(e.target.value);
                  setPage(0);
                }}
                placeholder="Pretraži"
              />
            </Form.InputGroup>
          </Button.List>
        </Card.Header>
        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader colSpan={2}>Nagrada</Table.ColHeader>
              <Table.ColHeader>Sponzor</Table.ColHeader>
              <Table.ColHeader>Korisničko ime</Table.ColHeader>
              <Table.ColHeader>Email</Table.ColHeader>
              <Table.ColHeader />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {visibleFulfillments.length === 0 ? (
              <Table.Row>
                <Table.Col colSpan={6}>
                  <p
                    style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      paddingTop: "1em",
                      color: "#999"
                    }}
                  >
                    {query.trim().length === 0
                      ? "Nema nagrada"
                      : "Nema nagrada za uneti upit"}
                  </p>
                </Table.Col>
              </Table.Row>
            ) : (
              visibleFulfillments
                .slice(itemsPerPage * page, itemsPerPage * (page + 1))
                .map((item, i) => (
                  <Table.Row key={item.id}>
                    <Table.Col className="w-1">
                      <Avatar imageURL={item.purchase.product.image_url} />
                    </Table.Col>
                    <Table.Col>{item.purchase.product.name}</Table.Col>
                    <Table.Col>{item.purchase.product.sponsor}</Table.Col>
                    <Table.Col>{item.purchase.user.display_name}</Table.Col>
                    <Table.Col>{item.purchase.user.email}</Table.Col>
                    <Table.Col alignContent="right">
                      {item.status === "unfulfilled" ? (
                        <Button
                          color="primary"
                          onClick={() => fulfill(item.id)}
                        >
                          Preuzmi
                        </Button>
                      ) : (
                        <Badge color="success">PREUZETO</Badge>
                      )}
                    </Table.Col>
                  </Table.Row>
                ))
            )}
            {numPages === 1 ? null : (
              <Table.Row>
                <Table.Col colSpan={6} alignContent="right">
                  <Pagination
                    {...{
                      setPage,
                      page,
                      numPages
                    }}
                  />
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card>
    );
  }
);

export function Admin({ history }) {
  const context = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<Fulfillment[]>([]);
  const [showFulfilled, setShowFulfilled] = useState(false);

  async function fulfill(id: string) {
    await client.post("/fulfillments/" + id, {
      params: { status: "fulfilled", ts: Date.now() }
    });
    const fulfillments = await client.get("/fulfillments", {
      params: { ts: Date.now() }
    });
    context.setFulfillments(fulfillments);
  }

  async function getData() {
    try {
      setIsLoading(true);
      const fulfillments = await client.get("/fulfillments", {
        params: { ts: Date.now() }
      });
      context.setFulfillments(fulfillments);
    } catch (e) {
      console.log("fetch data failed", e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setItems(
      context.fulfillments.filter(item =>
        showFulfilled
          ? item.status === "fulfilled"
          : item.status === "unfulfilled"
      )
    );
  }, [context.fulfillments, showFulfilled]);

  function handleFilterChange(params: { showFulfilled: boolean }) {
    setShowFulfilled(params.showFulfilled);
  }

  if (client.authenticated) {
    return (
      <SiteWrapper logout={true}>
        <Page.Content>
          <Grid.Row cards={true}>
            <Grid.Col width={12}>
              {isLoading ? (
                <p
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    padding: "2em",
                    color: "#999"
                  }}
                >
                  Učitavanje u toku...
                </p>
              ) : (
                <FulfillmentsTable
                  {...{
                    fulfill,
                    fulfillments: items,
                    onFilterChange: handleFilterChange,
                    otherListCount: context.fulfillments.length - items.length
                  }}
                />
              )}
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  } else {
    history.push("/");
    return null;
  }
}
